
body.en {
  direction: ltr;
  #home {
    .main_butttons {
      button {
        max-height: 50px;
        line-height: 50px;
        padding: 0;
      }
    }
  }

  #video {
    p.desc {
      max-width: 530px;
      margin: 0 auto 15px;
      font-size: 19px;
      text-align: left;
    }
    .main-description-box {
      margin: 50px 0 80px;
    }

    .info-wrap {
      .info-row {
        background: rgba(0, 0, 0, 0.7);
        border-top: 1px solid #4D4E50;
        padding: 25px 0;
        @include mq(sm) {
          padding: 0;
          border-top: none;
        }
        .item {
          color: #B2B2B2;
          padding: 0 60px 0 30px;
          @include mq(sm) {
            padding: 15px 0;
            border-bottom: 1px solid #4D4E50;
          }
        }
        .inner-row {
          max-width: calc(100% - 30px);
          margin: auto;
        }
        h3 {
          font-size: 32px;
          margin: 0 0 15px 0;
          color: #FFFFFF;
          @include mq(sm) {
            text-align: center;
            font-size: 28px;

          }

        }
        img {
          margin: 0 0 15px 0;
          width: 100%;
        }
        p {
          margin: 0 0 15px 0;
        }
        a {
          color: #77DFFD;
        }
      }

    }
  }
  #lineup {
    .lineup-table {
      .lineup-row {
        .lineup-text {
          float: right;
          .info.design2 {
            .label {
              text-align: left;
              float: right;
            }
            .txt {
              float: right;
            }
          }
        }
        .lineup-img {
          float: left;
        }
      }
    }
  }
  #orderPanel {
    .order-wrap {
      .order-content {
        hr {
          margin: 0 30px 20px 15px;
        }
        .detail {
          &.transport_check,
          &.transport_price,
          &.area-select {
            float: left;
            @include mq(sm) {
              float: none;
            }
          }
          > .col-xs-4, > .col-xs-8 {
            float: left;
            @include mq(sm) {
              padding: 0 10px 0 0;
            }
          }
          .total {
            .col-xs-8 {
              @include mq(sm) {
                padding: 0 10px 0 0;
              }
            }
          }
        }
      }
      #ticket_price, .cur, #total_price,#transport_price {
        margin: 0;line-height: 24px;
      }
      #ticket_price, #total_price,#transport_price {
        padding-top: 8px;
      }
      .cur{ margin: 0 5px 0 0;    direction: ltr;}
      .approx{
        direction: ltr;
        font-size: 12px;
      }
    }
  }

  #information, #deadsea, #information2 {
    .info-wrap {
      .info-row {
        h3 {
          text-align: left;
        }
        .item {
          h3 {
            text-align: left;
          }
          a.toorder {
            float: right;
          }
          .buy{
            .price{ float: left;    margin: 5px 5px 5px 0;    direction: ltr;
              .cur{}
            }

          }
        }

      }
    }
    ol {
      padding: 0 0 0 15px;
    }
  }
  #deadsea {
    .imigdeadsea {
      max-width: 500px;
      display: block;
      text-align: center;
      margin: auto;
    }
  }

  #footer {
    .item {
      float: left;
    }
    .contact-info {
      .item {
        float: left;
        width: 50%;
        &.mid {
          border-right: none!important;
        }
      }
      h5, h3, .phone {
        text-align: left;
      }
      .contact-inner.b {
        max-width: 210px;
      }
    }
    .bottom-footer {
      .mid {
        padding: 0 0 0 40px;
      }
      .newsletter {
        button {
          float: left;
        }
      }
    }
    .contact {
      text-align: left;
      i {
        font-size: 18px;
        position: relative;
        top: 2px;
        width: 25px;
      }
    }
  }

  #thanku {
    background: #000000;
    color: #FFFFFF;
    padding: 40px;
    text-align: center;
    .thank-content {
      text-align: left;
      padding: 40px;
      p {
        margin: 0 0 10px 0;
      }
    }
  }
}


