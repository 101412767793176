$mobheaderHeight: 70px;
$mobheaderHeight_heb: 115px;
/*home
---------------------------------------------------*/

.heb {
  #home {
    @include mq(sm) {
      margin-top: $mobheaderHeight_heb;
    }

    .main_butttons {
      @include mq(sm) {
        height: $mobheaderHeight_heb;
      }
    }

  }

}

#home {
  background: #000000 url(../images/home-bg_logo.jpg) top center no-repeat;
  background-size: cover;
  .section-inner {
    max-width: calc(100% - 40px);
    padding: 20px 0;
    @include mq(sm) {
      padding: 10px 0;

    }
  }

  @include mq(sm) {
    margin-top: $mobheaderHeight;
    background-image: url(../images/home-bg_logo_mob.jpg);
    background-size: contain;
  }
  header {
    .logos {
      float: left;
      @include mq(sm) {
        text-align: left;
        img {
          max-width: 100%;
        }
      }
    }

    .lang-switch {
      display: inline-block;
      float: right;
      direction: ltr;
      font-weight: 900;
      font-size: 22px;
      @include mq(sm) {
        margin-top: 10px;
      }
      > * {
        display: inline-block;
        margin: 0 2px 0 0;
      }
      a {
        color: #FFFFFF;
      }
      .fa-globe {
        font-size: 20px;
      }
      .lang-list {
        display: none;
        font-size: 18px;
        a {
          color: #FFFFFF;
          display: block;
          i {
            display: inline-block;
            margin-right: 8px;
            opacity: 0;
          }
        }
      }
      &:hover .lang-list {
        display: block
      }
    }
  }
  .main_butttons {
    font-size: 0;
    position: absolute;
    width: 100%;
    bottom: 17%;
    max-width: calc(100% - 40px);
    &.scrollfix {
      position: fixed;
      width: 100%;
      top: 0;
      z-index: 99;
      left: 0;
      background: rgba(0, 0, 0, 0.8);
      height: auto;
      padding: 15px 20px;
      bottom: auto;
      max-width: 100%;

      a#hatavatop {
        margin: 0 0 0 10px;
      }
      br {
        display: none;
      }
      #moreinfo {
        float: left;
      }

    }

    @include mq(md) {
      bottom: 30px;
    }

    @include mq(sm) {
      position: fixed;
      right: 0;
      bottom: auto;
      top: 0;
      background: #000000;
      padding: 15px;
      max-width: 100%;
      height: $mobheaderHeight;
      z-index: 9;
    }
    > div {
      vertical-align: bottom;
      font-size: 15px;
      vertical-align: top;
    }
    button {
      @include border-radius(10px);
      padding: 15px 10px;
      min-width: 200px;
      font-weight: bold;
      text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
      &#phonebtn {
        background: #FFFFFF;
        color: #000000;
        * {
          display: inline-block;
          margin: 0 0 0 10px;
          @include mq(xs) {
            margin: 0;
          }
        }
        img {
          @include mq(xs) {
            width: 40px;
            margin-left: 5px;
          }
        }
      }
      &#moreinfo {
        background: #F84927;
        color: #FFFFFF;
      }
      @include mq(xs) {
        padding: 10px 5px;
        min-width: 0;
        width: 98%;
        font-size: 14px;
      }

    }

    a#hatavatop {
      background: #0150C7;
      color: #FFFFFF;
      display: inline-block;
      font-weight: bold;
      border-radius: 10px;
      background-clip: padding-box;
      text-align: center;
      font-size: 14px;
      text-decoration: none;
      min-width: 200px;
      padding: 15px 10px;
      margin: 10px 0 0 0;
      @include mq(xs) {
        padding: 10px 5px;
        min-width: 0;
        width: 98%;
        font-size: 14px;
        margin: 10px auto 0;
      }
    }

  }

}

/*video
---------------------------------------------------*/
#video {
  .iconssocial {
    a {
      font-size: 37px;
      display: inline-block;
      margin: 25px 5px 10px;
      color: #FFFFFF;
    }
  }
  h3.video-title {
    margin: 50px 0 0 0;
  }
  #video-wrap {
    margin: 20px 0;
    height: 384px;
    overflow: hidden;
    @include mq(md) {
      width: 700px;
      height: 281px;
      margin: 50px auto;
    }
    @include mq(sm) {
      width: 95%;
      height: auto;
    }
    .embed-container {
      margin-top: -78px;
      @include mq(md) {
        margin-top: -8%;
      }
      @include mq(sm) {
        margin-top: 0;
      }
    }

  }
  p.desc {
    max-width: 530px;
    margin: 0 auto 15px;
    font-size: 19px;
    text-align: right;
    line-height: 22px
  }
  .main-description-box {
    margin: 50px 0 80px;
  }

  .info-wrap {
    .info-row {
      background: rgba(0, 0, 0, 0.7);
      border-top: 1px solid #4D4E50;
      padding: 25px 0;
      @include mq(sm) {
        padding: 0;
        border-top: none;
      }
      .item {
        color: #B2B2B2;
        padding: 0 60px 0 30px;
        @include mq(sm) {
          padding: 15px 0;
          border-bottom: 1px solid #4D4E50;
        }
      }
      .inner-row {
        max-width: calc(100% - 30px);
        margin: auto;
      }
      h3 {
        font-size: 32px;
        margin: 0 0 15px 0;
        color: #FFFFFF;
        @include mq(sm) {
          text-align: center;
          font-size: 28px;

        }

      }
      img {
        margin: 0 0 15px 0;
        width: 100%;
      }
      p {
        margin: 0 0 15px 0;
      }
      a {
        color: #77DFFD;
      }
    }

  }
}

/*lineup
---------------------------------------------------*/
#lineup {
  .section-inner {
    @include mq(sm) {
      max-width: 100%;
    }
  }
  .section_title {
    h2 {
      font-size: 60px;
      @include mq(sm) {
        font-size: 35px;
      }
    }
  }
  .lineup-table {

    .lineup-row {
      background: rgba(0, 0, 0, 0.5);
      border-top: 1px solid #4D4E50;
      padding: 15px 0;
      &.header-row {
        padding: 15px 0;
      }
      .inner-row {
        max-width: 765px;
        max-width: 90%;
        margin: auto;
      }

      .lineup-img {
        @include mq(sm) {
          float: none !important;
          margin: 10px auto;
          text-align: left;
          padding: 0 15px;
        }
        float: left;
        img {
          max-width: 180px;
        }

        .timing {
          color: #40c9dd;
          font-size: 28px;
          font-weight: bold;
          >span {
            color: #fff;
          }
        }

      }
      .lineup-text {
        margin-top: 15px;
        float: left;
        padding: 0;
        >* {
          max-width: 500px;
        }
        h5{
          margin: 0;
          color: #40c9dd;;
          font-size: 17px;
        }
        @include mq(sm) {
          padding: 0 15px;
          width: 100%;
          float: none;
        }
        h3 {
          font-size: 26px;
          margin-bottom: 10px;
        }
        .info {
          margin: 15px 0;
          .label, strong {
            @include mq(sm) {
              /*font-size: 14px;*/
            }
          }
          &.design2 {
            .detail {
              margin: 0 0 10px 0;
              height: 50px;
            }
            .label {
              font-weight: 700;
              display: block;
              float: left;
              width: calc(100% - 65px);
              text-align: right;
              color: #FFFFFF;
              margin-top: 5px;
            }
            .txt {
              font-size: 13px;
              display: block;
              float: left;
              width: calc(100% - 65px);
              line-height: 14px;
              color: #FFFFFF;
            }
            strong {
              background: #00BB9D;
              display: inline-block;
              width: 55px;
              height: 55px;
              line-height: 11px;
              text-align: center;
              color: #FFFFFF;
              font-weight: 700;
              float: none;
              font-size: 19px;
              vertical-align: top;
              padding: 12px 5px;
              @include border-radius(100%);

            }

          }
          .txt {
            color: #B2B2B2
          }

        }
        &.newlook{
          margin: 0;
          h3 {
            font-size: 24px;
            margin: 0;
            u{
              font-size: 17px;
              text-decoration: none;
            }

          }
        }
        &.main-show{
          h5 {color: #fff;font-size: 24px;}
          h3 {    font-size: 34px;}
        }
      }

      &.jmj-shows{
        background-image: url(../images/main_show_bg.png);
        background-size: cover;
        background-position: top left;
        border-top: 1px solid #fff;
        position: relative;
        .inner-row{
          position: relative;
          z-index: 4;
        }
        img.jmj-shows-img{
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 2;
          @include mq(sm){
            opacity: 0.5;
          }
        }
      }
    }
  }
}

/*orderPanel
---------------------------------------------------*/
#orderPanel {
  .section-inner {
    @include mq(sm) {
      max-width: 100%;
    }
  }
  .section_title {
    @include mq(sm) {
      background: #232323;
      padding: 10px 0;
    }
    h2 {
      font-size: 60px;
      @include mq(sm) {
        font-size: 35px;
      }
    }
  }
  .order-wrap {
    background: #232323;
    position: relative;
    @include mq(sm) {
      padding-top: 0;
    }
    .map {
      padding: 0;
      position: static;
      width: calc(100% - 60px);
      margin: auto;
      padding-top: 30px;
      @include mq(sm) {
        position: static;
        width: 100%;
        padding-top: 0;
      }

      #MainMap {
        area {
          cursor: pointer;
        }
      }
      img {
        width: 100%;
        @include mq(sm) {
          margin: auto;
          width: 100%;
        }
      }
    }

    .order-content {
      max-width: 780px;
      margin: auto;
      padding: 30px 30px 0 30px;
      @include mq(sm) {
        padding: 15px 0px 0;
      }

      hr {
        margin-top: 0;
        margin-bottom: 20px;
        border: 0;
        border-top: 1px solid #656565;
        margin: 0 15px 20px 30px;
      }

      .detail {
        margin: 0 0 0px 0;
        @include mq(sm) {
          margin: 0 0 0px 0;
        }
        > .col-xs-4,
        > .col-xs-8 {
          float: right;

          @include mq(sm) {
            padding-left: 0;
          }
        }
        .label {
          line-height: 36px;
        }
        &.area-select,
        &.transport_check,
        &.transport_price {
          float: right;
          @include mq(sm) {
            float: none;
          }
        }

      }
      select {
        background: transparent;
        padding: 0 10px;
        height: 36px;
        line-height: 36px;
        @include border-radius(10px);
        border: 1px solid #656565;
        width: 100%;
        outline: none !important;
        option {
          background: #232323;
        }
      }
      input[type="radio"], input[type="checkbox"] {
        margin: 0px 0 0 5px;
        display: inline-block;
        vertical-align: top;
        line-height: 36px;
        height: 36px;
      }
      .number-box {
        width: 100%;
      }
      .qt_btns {
        background: transparent;
        padding: 0 10px;
        border-radius: 10px;
        border: 1px solid #656565;
        width: 100%;
        display: block;
        text-align: center;
        height: 36px;
        line-height: 36px;
        span {
          display: inline-block;
          font-size: 30px;
          color: #A1A1A1;
          font-weight: 900;
          width: 30px;
          cursor: pointer;
          &.plus {
            float: right;
            margin-right: -10px;
          }
          &.minus {
            float: left;
            margin-left: -10px;
          }
        }
        input {
          background: transparent;
          border: none;
          width: 50px;
          text-align: center;
        }
      }
    }
    footer {
      padding: 20px 0px;
      background: #333333;
      @include mq(sm) {
        padding: 5px 0px;
      }

      .age-notice {
        font-size: 12px;
      }

      .totalwrap {
        max-width: 780px;
        margin: auto;
        padding: 0 30px;
        @include mq(sm) {
          padding: 0 0px 15px;
        }
        .addtocart-wrap {
          @include mq(sm) {
            padding: 0;
          }
        }
        #addtocart {
          background: #F84927;
          color: #FFFFFF;
          display: block;
          width: 100%;
          height: 36px;
          line-height: 36px;
          font-weight: bold;
          @include border-radius(10px);
        }
      }
      .label {
        line-height: 36px;
      }
    }
    #ticket_price, .cur, #total_price, #transport_price {
      line-height: 36px;
    }
  }
}

/*information
---------------------------------------------------*/
#information, #information2, #deadsea {
  .section_title {
    h2 {
      font-size: 60px;
      @include mq(sm) {
        font-size: 45px;
      }
    }
  }
  ol {
    padding-right: 15px;
  }
  .info-wrap {
    .info-row {
      background: rgba(0, 0, 0, 0.5);
      border-top: 1px solid #4D4E50;
      padding: 25px 0;
      @include mq(sm) {
        padding: 0;
        border-top: none;
      }
      .item {
        color: #B2B2B2;
        padding: 0 60px 0 30px;
        @include mq(sm) {
          padding: 15px 0;
          border-bottom: 1px solid #4D4E50;
        }
        &.r {
          padding: 0 30px 0 60px;
          float: right;
          @include mq(sm) {
            float: none;
            padding: 15px 0;
          }
        }
        .buy {
          font-size: 24px;
          color: #FFFFFF;
          margin: 10px 0;
          a.toorder {
            background: #F84927;
            color: #FFFFFF;
            padding: 5px 15px;
            @include border-radius(8px);
            margin: 5px 0;
            display: inline-block;
            font-size: 15px;
            font-weight: bold;
            text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
            text-decoration: none;
            min-width: 115px;
            text-align: center;
            float: left;
          }
          .price {
            display: inline-block;
            vertical-align: top;
            line-height: 30px;
            margin: 5px 0 5px;
            direction: rtl;
            float: right;
            .cur {
              display: inline-block;
              margin: 0 2px;
            }
          }
        }
      }
      .inner-row {
        max-width: calc(100% - 30px);
        margin: auto;
      }
      h3 {
        font-size: 35px;
        text-align: right;
        margin: 0 0 15px 0;
        @include mq(sm) {
          text-align: center;
          font-size: 28px;

        }

      }
      img {
        margin: 0 0 15px 0;
        width: 100%;
      }
      p {
        margin: 0 0 15px 0;
      }
      a {
        color: #77DFFD;
      }
      ul.check {

        list-style: none;
        li {
          color: #FFFFFF;
          width: 48%;
          display: inline-block;
          &.wide {
            width: 100%;
          }
        }
      }
    }

  }
}

#premiumdetaisl {
  input {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    height: 30px;
    line-height: 30px;
    border: none;
  }

  input[type="text"], input[type="email"] {
    padding: 0 5px;
    color: #000000;
    @include border-radius(5px);
    @include mq(sm) {
      display: block;
      width: 100%;
      margin: 10px 0;
    }
  }
  input[type="submit"] {
    background: #F84927;
    color: #FFFFFF;
    padding: 0px 15px;
    @include border-radius(8px);
    font-size: 15px;
    font-weight: bold;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
  }
}

#information2, #deadsea {
  .info-wrap {
    .info-row {
      background: transparent;
      border-top: none;
      .inner-row {

      }
      .item {
        &.imigdeadsea {
          padding-top: 160px;
          @include mq(sm) {
            padding-top: 0px;
          }
        }
        @include mq(sm) {
          border: none;
        }
        h3 {
          font-size: 35px;
          text-align: right;
          color: #FFFFFF;
          font-weight: 900;
          margin-bottom: 25px;
          @include mq(sm) {
            font-size: 28px;
          }
        }
        img {
          width: auto;
          max-width: 100%;

        }
        a#waze img {
          width: 100%;
          max-width: 50px;
        }
        .out-link {
          /* margin: 0 0 5px 0;*/
          a {
            color: #77DFFD;
          }
          i {
            color: #FFFFFF;
          }
        }
      }

    }
  }

}

/*deadsea...........................................................*/

#deadsea {
  padding: 0 0 20px 0;
  color: #FFFFFF;
  .info-wrap {
    .info-row {
      .item {
        &.imigdeadsea {
          img {
            max-width: 80%;
            margin: auto;
            display: block;
            @include mq(sm) {
              margin: 20px auto;
            }
          }
        }
      }
    }
  }

}

/*accordion*/
.accod_section {
  max-width: 960px;
  text-align: right;
  font-size: 19px;
  line-height: 22px;
  margin: 35px 0 0 0;
  @include mq(sm) {
    font-size: 17px;
    line-height: 20px;
  }
  .section_title {

    h2 {
      @include mq(sm) {
        font-size: 27px;
      }

    }
  }
}
.accord .accord-item>h3 {
  text-align: left;
}
.accord {
  .accord-item {
    margin: 0 0 15px 0;
    .accord-title {
      cursor: pointer;
      text-align: right;
      font-size: 27px;
      line-height: 27px;
      margin-bottom: 10px;
      max-width: 530px;
      margin: 0 auto 15px;
      @include mq(sm) {
        font-size: 24px;
        line-height: 22px;
      }

      .close-icon {
        display: none;
      }
      .open-icon {
        display: inline-block;
        vertical-align: middle;
      }
      &.open {
        .close-icon {
          display: inline-block;
          vertical-align: middle;
        }
        .open-icon {
          display: none
        }
      }
    }
    .accord-content {
      display: none;
      padding: 30px 60px;
      background: rgba(0, 0, 0, 0.7);
      @include mq(sm) {
        padding: 10px 20px;
      }
      h4 {
        font-size: 26px;
        margin-bottom: 10px;
        @include mq(sm) {
          font-size: 22px;
        }
      }
      p {
        margin: 0 0 10px 0;
      }
    }
  &:nth-child(even){
    .accord-title{
      text-align: left;
      i {
       // float: left;
       // margin: 0 5px 0 0;
      }
    }
  }
  }

}