body.en{
  &.ru {
    #video {
      p.desc {
        max-width: 100%;
      }
    }
  }
  p.desc {
    max-width: 100%;
    margin: 0 auto 15px;
    font-size: 19px;
    text-align: left;
  }
}


