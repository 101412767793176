#footer {
  background: #e4e4e4 url(../images/footer_bg.jpg) bottom center no-repeat;
  background-size: cover;
  min-height: 200px;
  @include mq(sm) {
    background: #E4E4E4 url(../images/footer_bg.jpg) bottom center no-repeat;
    background-size: auto;
  }
  .section-inner {
    @include mq(sm) {
      max-width: 100%;
    }
  }

  h5, h3 {
    color: #494949;
    font-size: 18px;
    font-weight: 900;
    margin: 0 0 10px 0;
    @include mq(sm) {
      text-align: center;
    }
  }
  h3 {
    font-size: 24px;

  }
  .contact {
    direction: ltr;
    list-style: none;
    color: #494949;
    margin-top: 10px;
    text-align: right;
    li {
      display: inline-block;
    }
    i {
      display: inline-block;
      margin-right: 2px;
      width: 20px;
    }
    .icon-facebook {
      width: 25px;
      margin: 0 5px;
      vertical-align: top;
      display: inline-block;
    }
    a {

      color: #00CAB4;
      font-size: 22px;
      line-height: 25px;
      display: inline-block;
      vertical-align: top;
    }
  }

  .item {
    float: right;
    @include mq(sm) {
      float: none;
      margin: 0 0 25px 0;
    }
    .footerlogo {
      display: inline-block;
      @include mq(sm) {
        width: 100%;
        text-align: center;
      }
      img {
        max-width: 100%;
      }
    }
    .inner-footeritem {
      max-width: 200px;
      @include mq(sm) {
        max-width: 100%
      }
      .contact {
        margin-top: 15px;
        @include mq(sm) {
          width: 49%;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
  .contact-info {
    padding: 10px 0;
    text-align: center;
    @include mq(sm){
      padding: 0 15px;
    }
    .item {
      padding: 0 70px;
      display: inline-block;
      @include mq(sm){
        padding: 0;
        width: 100%;
      }
      &.mid {
        border-right: 1px solid #9E9E9E;
        border-left: 1px solid #9E9E9E;
        @include mq(sm) {
          border-top: 1px solid #9E9E9E;
          border-bottom: 1px solid #9E9E9E;
          border-right:none;
          border-left: none;
          padding: 20px 0;
        }
      }
    }
    color: #484848;
    h5, h3 {
      color: #000000;
      text-align: right;
    }
    .contact-inner {
      margin: auto;
      &.a {
        max-width: 180px;
      }
      &.b {
        max-width: 230px;
      }
      &.c {
        max-width: 180px;
      }
      @include mq(sm) {
        max-width:100%!important;
      }

    }
    .phone {
      direction: ltr;
      font-weight: 900;
      font-size: 43px;
      margin: 10px 0 0 0;
      text-align: right;
      a{
        text-decoration: none;
        color: #484848;
      }
      span {
        position: relative;
        top: 12px;
        left: -1px;
      }
      &.bigphone {
        font-size: 60px;
        margin: 20px 0 0 0;
        span {
          top: 17px;
          left: -2px;
        }
      }
    }
  }
  .sponsers {
    padding: 0 10px 30px 0;

    .sponsers-logos {
      text-align: center;
      font-size: 0;
      .footerlogo {
        display: inline-block;
        padding: 15px 15px;
        width: 14.2%;
        text-align: center;
        @include mq(sm) {
          width: 31%;
        }
        img{
          max-width: 100%;
        }
      }
    }
  }
  .section-inner {
    border-top: 1px solid #DDDDDD;
  }
  .bottom-footer {
    .contact {
      @include mq(sm) {
        text-align: center;
      }

    }
    .mid {
      padding: 0 40px 0 0;
      @include mq(sm) {
        padding: 0;
      }
    }
    .newsletter {
      .newsletter-title {
        @include mq(sm) {
          margin-bottom: 5px;
        }
      }
      h5 {
        display: inline-block;
        margin: 0;
        line-height: 40px;
        @include mq(sm) {
          display: block;
        }
      }
      input {
        padding: 0 10px;
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #FFFFFF;
        color: #000;
        @include border-radius(10px);
        border: 1px solid #DDDDDD;

        @include mq(sm) {
          margin-right: 15px;
          display: block;
          max-width: calc(100% - 15px);
        }
      }

      button {
        @include border-radius(10px);
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        font-weight: bold;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
        background: #00CAB4;
        color: #FFFFFF;
        width: 100%;
        margin: auto;
        display: block;
        max-width: 110px;
        float: right;
        @include mq(sm) {
          max-width:200px;
          float: none;
        }
      }
    }
  }

  #copyrightama {
    padding: 0 0 15px 0;
  }

}

#mep_0, .mejs-offscreen {
  width: 0px !important;
  height: 0px !important;
  display: none;
}

.audioControl {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  img {
    cursor: pointer;
    width: 100%
  }
  .play {
    display: none;
  }
  .pause {
    display: block;
  }
  &.off {
    .play {
      display: block;
    }
    .pause {
      display: none;
    }
  }
}


a#back-top {
  position: fixed;
  bottom: 0;
  display: none;
  right: 0;
  width: 45px;
  height: 45px;
  background:#F84927;
  text-align: center;
  i {
    color: #fff;
    font-size: 25px;
    line-height: 45px;
  }
  &.scrollfix {
    display: block;
  }
}
