/* ==========================================================================
   Mixins
   ========================================================================== */

@mixin opacity($opacity) {

  $opacity-ie: $opacity * 100;
  -moz-opacity:$opacity;
  -khtml-opacity: $opacity;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha"(Opacity=$opacity-ie);
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=$opacity-ie);
  filter: alpha(opacity=$opacity-ie);
  opacity: $opacity;

}
/*----------------------------------------------------------------------*/

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
/*----------------------------------------------------------------------*/
@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
@mixin transform($args) {
  -moz-transform: $args;
  -webkit-transform: $args;
  -o-transform: $args;
  -ms-transform: $args;
  transform: $args;
}
/*----------------------------------------------------------------------*/

@mixin input_placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
/*----------------------------------------------------------------------*/

@mixin flexbox($align,$justify) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: $align;
  justify-content:$justify;
}
@mixin flex($values) { //(flex-grow, flex-shrink , flex-basis)
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -webkit-flex:  $values;
  -ms-flex:  $values;
  flex:  $values;
}
/*----------------------------------------------------------------------*/

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-radius-top($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-radius-right($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
  background-clip: padding-box;
}
@mixin border-radius-bottom($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  background-clip: padding-box;
}
@mixin border-radius-left($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
  -webkit-border-top-left-radius: $radius;
  border-top-left-radius: $radius;
  background-clip: padding-box;
}


/*----------------------------------------------------------------------*/
//media queries breakpoints (taken from bootstrap_variables.scss)



@mixin mq($point) {
  @if $point == xl {
    @media (min-width: 1350px) { @content; }
  }
  @if $point == lg {
    @media (min-width: 1200px) { @content; }
  }
  @else if $point == md {
    @media (max-width: 992px) { @content; }
  }
  @else if $point == sm {
    @media (max-width: 760px)  { @content; }
  }
  @else if $point == xs {
    @media (max-width: 480px)  { @content; }
  }
  // @include mq(xl) { width: 1330px; }
}