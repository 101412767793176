/*fonts*/

@font-face {
  font-family: 'almoni-dl';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/almoni-dl-aaa-700.eot');
  src: url('../fonts/almoni-dl-aaa-700.eot#iefix') format('embedded-opentype'), url('../fonts/almoni-dl-aaa-700.woff') format('woff'), url('../fonts/almoni-dl-aaa-700.ttf') format('truetype');
  /*(bold)*/
}

@font-face {
  font-family: 'almoni-dl';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/almoni-dl-aaa-900.eot');
  src: url('../fonts/almoni-dl-aaa-900.eot#iefix') format('embedded-opentype'), url('../fonts/almoni-dl-aaa-900.woff') format('woff'), url('../fonts/almoni-dl-aaa-900.ttf') format('truetype');
  /*(black)*/
}

@font-face {
  font-family: 'almoni-dl';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/almoni-dl-aaa-300.eot');
  src: url('../fonts/almoni-dl-aaa-300.eot#iefix') format('embedded-opentype'), url('../fonts/almoni-dl-aaa-300.woff') format('woff'), url('../fonts/almoni-dl-aaa-300.ttf') format('truetype');
  /*(light)*/
}

@font-face {
  font-family: 'almoni-dl';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/almoni-dl-aaa-400.eot');
  src: url('../fonts/almoni-dl-aaa-400.eot#iefix') format('embedded-opentype'), url('../fonts/almoni-dl-aaa-400.woff') format('woff'), url('../fonts/almoni-dl-aaa-400.ttf') format('truetype');
  /*(regular)*/
}



@font-face {
  font-family: 'asteraregular';
  src: url('../fonts/astera_v2.woff2') format('woff2'),
  url('../fonts/astera_v2.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}