
body {
  direction: rtl;
  font-family: "almoni-dl", sans-serif;
  font-size: 16px;
  line-height: 20px;
  @include mq(sm) {
    font-size: 14px;
    line-height: 18px;
  }
}

button {
  border: none;
  outline: none;
  &:hover, &:focus {
    outline: none;
  }
}
iframe[name="google_conversion_frame"] {
  height: 0px!important;
  display: none!important;
}

#wrap {

}
.rtl{
  direction: rtl;
}
.full-page {
  position: relative;
}

.inner_site {
  background: #000000 url(../images/all_sections_bg.jpg) bottom center no-repeat;
  background-size: cover;
  background-position-y: 0 ;
  @include transition( background 1ms linear);
/*
  &[data-on="home"]{
    background-position-y: 25% ;
  }
  &[data-on="video"]{
    background-position-y: 25% ; }
  &[data-on="lineup"]{
    background-position-y: 25% ;
  }
  &[data-on="information"]{
    background-position-y: 90%;
  }
  &[data-on="orderPanel"]{
    background-position-y: 90%;
  }
  &[data-on="information2"]{
    background-position-y: 90%;
  }
  &[data-on="deadsea"]{
    background-position-y: 90%;
  }
*/
  @include mq(sm) {
    background: #000000 url(../images/all_sections_bg_mob.jpg) bottom center no-repeat;
  }

}

h1, h2, h3, h4, p {
  margin: 0;
}

strong {
  font-weight: 900;
}

.pink{
  color: #ff7dbc;
}
.yellow{
  color: #ffe086;
}
.blue{
  color: #77dffd;
}
.ltr-text{
direction: ltr;
display: inline-block;
}

.electro {
  direction: ltr;
  /*font-family: 'asteraregular',serif;*/
  font-weight: bold;
  text-transform: uppercase;
}

.detail {
  .label {
    color: #B2B2B2;
    padding: 0;
    font-size: initial;
    font-weight: normal;
  }
  * {
    display: inline-block;
  }

}
.fff{
  color: #fff;
}


.section-inner {
  max-width: 960px;
  margin: auto;
  padding: 20px 0;
  color: #FFFFFF;
  @include mq(sm) {
    max-width: calc(100% - 30px);
  }
  .section_title {
    text-align: center;
    padding: 20px 0;
    @include mq(sm) {
      padding: 20px 0;
    }

    h2 {
      font-size: 45px;
      font-weight: 900;
      text-shadow:2px 3px 10px rgba(0, 0, 0, 0.5);
    }
  }
  .section_content {

  }
}

#fanzon-tooltip {
  position: relative;
  padding: 0 10px 10px 10px;
  span.tooltip-btn {
    font-size: 18px;
    text-decoration: underline;
    cursor: pointer;
    i{
      font-size: 14px;
    }
  }
  .tooltip-text {
    display: none;
    position: absolute;
    background: #ddd;
    padding: 20px;
    color: #000;
    z-index: 99;
    box-shadow: 2px 2px 8px rgba(0,0,0,0.5);
    bottom: 35px;
    p {
      margin: 10px 0;
    }
    .blosebtn{
      position: absolute;
      top: 5px;
      right: 5px;
      color: #000;
      font-size: 22px;
      cursor: pointer;
    }

  }
}

.hatavawrap{
  @include mq(sm) {
    padding: 0;
    margin: 10px 0;
  }
  a#hatava {
    background: #0150c7;
    color: #FFFFFF;
    display: block;
    width: 100%;
    height: 36px;
    line-height: 36px;
    font-weight: bold;
    border-radius: 10px;
    background-clip: padding-box;
    text-align: center;
    font-size: 14px;
    margin: 0 0;
    text-decoration: none;
  }

}
div#noticemsg {
  text-align: center;
  font-size: 25px;
  text-shadow: 2px 2px 3px rgba(0,0,0,0.8);
  font-weight: bold;
}