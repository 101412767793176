#thanku{
  background: #000;
  color: #fff;
  padding: 40px;
  text-align: center;
  .thank-content{
    text-align: right;
    padding: 40px;
    p{
      margin: 0 0 10px 0;
    }
  }
}